import api from "app/api"
import user from "common/src/user"
import hub from "common/src/hub"
//import { prompt, confirm } from "common/src/components/dialog/Alert"
//import productUrlById from "common/src/lib/url/productUrlById"
//import url2retailer from "common/src/lib/look/url2retailer"
import extractCatalogueId from "common/src/lambdalib/extractCatalogueId"
import extractUrlFromClick from "common/src/lambdalib/extractUrlFromClick"
import { createProducts } from "./catalogueLocal"


/*
export const showCreateForm = async () => {
    return await prompt({
                cls: "dialog-alert-v2 prompt-moodboard-create",
                label: "Name",
                okText: "Create",
                title: "Create moodboard",
                placeholder: 'For example "Shoes" or "Outfit for Jessica"',
                anim: ["a-zoom-in", "a-fadeout"]
            })
            .then(dlg => create(dlg.prompt))
            .catch(() => {});
}

export const showEditForm = async (m) => {
    return await prompt({
            cls: "dialog-alert-v2 prompt-moodboard-edit",
            label: "Name",
            okText: "Apply",
            cancelText: "Delete moodboard",
            title: "Edit moodboard",
            defaultValue: m.name,
            anim: ["a-zoom-in", "a-fadeout"]
        })
        .then(dlg => update(m.id, dlg.prompt))
        .then(() => true)
        .catch(async (action) => {
            if (action === "cancel") {
                const removed = await showRemoveDialog(m);
                return removed ? "removed" : false;
            }
            else {
                return false;
            }
        });
}

export const showRemoveDialog = async (m) => {
    return confirm({
        cls: "dialog-alert-v2",
        title: "Delete moodboard",
        message: "Are you sure?",
        anim: ["a-zoom-in", "a-fadeout"]
    })
    //.then(() => remove(m.id))
    .then(() => true)
    .catch(() => false);
}*/

export const create = async (name) => {
    await api.moodboard.create({ name, friId: user.id() });
    hub.dispatch("moodboard", "created", name);
};

export const update = async (id, name) => {
    await api.moodboard.update(id, { name });
    hub.dispatch("moodboard", "updated", id);
};

export const remove = async (id) => {
    await api.moodboard.remove(id);
    hub.dispatch("moodboard", "removed", id);
};

export const addProduct = async (id, product) => {

    const img = product.images[0];
    let url = product.url;
    if (!url && product.details && (product.details.click_url || 
                                    product.details.product_url || 
                                    product.details.url)) {
        url = extractUrlFromClick(product.details.click_url || 
                                    product.details.product_url || 
                                    product.details.url);
    }
    let { retailer, catalogueId } = extractCatalogueId(url);
    retailer = product.retailer || retailer;

    const cpids = await createProducts([ product.product_web_id || catalogueId ]);

    await api.moodboardCatalogueProduct.create({
        moodboardId: id,
        catalogueProductId: cpids[0],
        userId: user.id()
    })

    hub.dispatch("moodboard", "product-added", id);
};

export const removeProduct = async (mid, pid) => {
    await api.moodboardCatalogueProduct.remove({
        moodboardId: { _eq: mid },
        catalogueProductId: { _eq: pid }
    });
    //await api.moodboardProduct.remove(pid);
    hub.dispatch("moodboard", "product-removed", mid);
};

export const loadMoodboards = async (params) => {
    const { perPage = 40, page = 0, query = "", mine = false, type } = params;
    const where = { 
        _and: [
            { deleted: { _eq: false } },
            {
                _not: {
                    hideMoodboards: {
                        userId: { _eq: user.id() }
                    }
                }
            },
        ],
    };

    if (type) {
        switch (type) {
            case "all":
                where._and.push({
                    _or: [
                        {
                            friId: { _eq: user.id() },
                        },
                        {
                            pseTemplate: { _eq: true },
                        },
                    ],
                });
                break;
            case "my":
                where._and.push({ friId: { _eq: user.id() } });
                break;
            case "tf":
                where._and.push({ pseTemplate: { _eq: true } });
            default:
                break;
        }
    }

    if (query) {
        where._and.push({_or: [
            { name: { _ilike: `%${ query }%` }},
            { catalogueProducts: { catalogueProduct: { name: { _ilike: `%${ query }%` }}}},
            { catalogueProducts: { catalogueProduct: { brand: { _ilike: `%${ query }%` }}}}
        ]})
    }

    const order = { createdAt: "desc" };
    const limit = perPage;
    const offset = page * perPage;
    return await api.moodboard.list({ where, order, offset, limit }, null, true);
}