import { useMemo, useCallback, useState, useEffect } from "react"

//import { Link } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"
import { Input, Typography, Flex, App } from "antd"

//import Loader from "common/src/components/Loader"
//import { alert } from "common/src/components/dialog/Alert"

import Button from "../button/Button"
import FormInput from "../form/FormInput"
import { Form, FormContext, useFormFields } from "common/src/refactor/lib/form/Form"
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import Link from "common/src/components/Link"

import hub from "common/src/hub"
//import getUrl from "common/src/lib/url/get"
//import routes from "app/routes"
//import api from "app/api"
//import parseQueryString from "common/src/lib/url/parseQueryString"
//import getQueryParam from "common/src/lib/url/getQueryParam"
import isEmptyObject from "common/src/lib/js/isEmptyObject"
//import async from "common/src/lib/js/async"


import required from "common/src/refactor/lib/form/validator/required"
import isEmail from "common/src/refactor/lib/form/validator/isEmail"
import useDictRef from "common/src/hooks/useDictRef"
import useParams from "common/src/refactor/hooks/useSearchParams"
import Loader from "common/src/components/Loader"
import api from "common/src/api"

const fields = [
    {
        name: "email",
        validator: [ 
            [ required, "Please enter your email" ],
            [ isEmail, "Please enter a valid email" ]
        ],
        defaultValue: ""
    },
    {
        name: "password",
        validator: [ [ required, "Please enter your password" ]],
        defaultValue: ""
    }
]
const labelEmail = "Email";
const labelPassword = "Password";
const submitText = "Sign In";
const linkForgotText = "Forgotten password?";
const renewText = "Renew access"

function SignInForm({   subheader = null,
                        onAuthStateChange,
                        onError }) {

    const [ loading, setLoading ] = useState(false);
    const [ autoSign, setAutosign ] = useState(false);
    const [ tempExpired, setTempExpired ] = useState(false);
    const [ renewing, setRenewing ] = useState(false);
    const [ renewed, setRenewed ] = useState(false);
    const form = useMemo(() => new Form(fields), []);
    const {
        email, emailChange, emailError,
        password, passwordChange, passwordError
    } = useFormFields(["email", "password"], form);
    const ref = useDictRef({ email, password, tempExpired });
    const { message } = App.useApp();
    const { login: urlLogin, password: urlPass, lcode: urlCode } = useParams();

    const onAuthFailure = useCallback(
        async (err) => {
            if (!err.message?.match(/temporary password has expired/i)) {
                message.open({
                    content: "Incorrect username or password"
                });
            }
            setAutosign(false);
        },
        [ tempExpired ]
    );

    const onForgotClick = useCallback(
        () => {
            onAuthStateChange("forgotPassword")
        },
        [ onAuthStateChange ]
    );


    const checkContact = useCallback(
        (user) => {
            Auth.verifiedContact(user).then(data => {
                if (!isEmptyObject(data.verified)) {
                    onAuthStateChange('signedIn', user);
                } 
                else {
                    console.log("Contact verification is not implemented")
                }
            });
        },
        [ onAuthStateChange ]
    );

    const signIn = useCallback(
        async () => {
            
            setLoading(true);
    
            // this is an edited version of the code from aws-amplify-react
            try {
                const normEmail = ref.email?.toLowerCase() || urlLogin.toLowerCase();
                const user = await Auth.signIn(normEmail, ref.password || urlPass);
    
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    console.error("MFA is not configured")
                } 
                else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    onAuthStateChange('requireNewPassword', user);
                } 
                else if (user.challengeName === 'MFA_SETUP') {
                    console.error("MFA setup is not configured")
                } 
                else if (user.challengeName === 'CUSTOM_CHALLENGE' &&
                        user.challengeParam &&
                        user.challengeParam.trigger === 'true') {
                    console.error("Custom challenge is not configured")
                } 
                else {
                    checkContact(user);
                }
            } 
            catch (err) {
                if (err.code === 'UserNotConfirmedException') {
                    onAuthStateChange('confirmSignUp', { email });
                } 
                else if (err.code === 'PasswordResetRequiredException') {
                    onAuthStateChange('forgotPassword', { email });
                } 
                else if (err.message?.match(/temporary password has expired/i)) {
                    setTempExpired(true);
                }
                else {
                    onError(err);
                    setTempExpired(false);
                }
            } 
            finally {
                setLoading(false);
            }
        },
        [ onAuthStateChange, onError, checkContact, ref ]
    );


    const onSubmit = useCallback(
        async () => {
            const valid = await form.validateAll();
            if (valid) {
                signIn();
            }
        },
        [ signIn, form ]
    );

    const onRenewClick = useCallback(
        async () => {
            setRenewing(true);
            const resp = await api.unauth.post("/pse/renew", {
                body: {
                    email: urlLogin,
                    code: urlCode
                }
            });

            if (resp.success) {
                message.open({
                    content: "Access granted, check your emails",
                    duration: 1000,
                    style: {
                        marginTop: '55vh',
                    }
                });
                setRenewed(true);
            } else {
                message.open({
                    content: "Something went wrong, please try again",
                });
            }
            setRenewing(false);
        },
        [ urlLogin, urlCode ]
    );

    useEffect(
        () => {
            if (urlLogin && urlPass) {
                hub.dispatch("footer", "set-class", "autosign");
                emailChange(urlLogin);
                passwordChange(urlPass);
                setAutosign(true);
                onSubmit();
            }
            hub.listen("auth", "signIn_failure", onAuthFailure);
            return () => {
                hub.remove("auth", "signIn_failure", onAuthFailure);
                hub.dispatch("footer", "set-class", "");
            }
        },
        []
    );

    const onCloseClick = useCallback(
        () => {
            message.destroy();
        },
        [ ]
    )

    if (autoSign) {
        return (
            <Flex vertical align="center" className="page-signin-autosign">
                <Link className="page-signin-close" to="/" onClick={ onCloseClick }>
                    <IconClose/>
                </Link>

                <Link to="/"><Logo/></Link>
                <Typography.Title>Signing in....</Typography.Title>

                <Typography.Text>Signing you in as { urlLogin }</Typography.Text>

                <Loader size={ 36 }/>
            </Flex>
        )
    }

    if (tempExpired) {
        return (
            <Flex vertical align="center" className="page-signin-autosign">
                <Link className="page-signin-close" to="/" onClick={ onCloseClick }>
                    <IconClose/>
                </Link>

                <Link to="/"><Logo/></Link>
                <Typography.Title>Access expired</Typography.Title>

                <Typography.Text className="page-renew-text">Your sign in link has expired. Renew your unique link by tapping below</Typography.Text>

                <Button
                    text={ renewText }
                    disabled={ renewing || renewed }
                    onClick={ onRenewClick }
                    loading={ renewing }
                />
            </Flex>
        )
    }

    return (
        <FormContext.Provider value={ form }>
        <Flex vertical align="center" className="page-signin-form">
            
            <Link to="/"><Logo/></Link>
            <Typography.Title>Sign In</Typography.Title>

            <FormInput label={ labelEmail } error={ emailError }>
                <Input 
                    autoFocus
                    autoCorrect="off" 
                    autoCapitalize="none"
                    id="login-email"
                    placeholder="Enter email address"
                    name="email"
                    value={ email }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ emailChange }
                    data-cy="login-form-email"/>
            </FormInput>

            <FormInput label={ labelPassword } error={ passwordError }>
                <Input.Password
                    id="login-password"
                    name="password"
                    placeholder="Enter your password"
                    value={ password }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ passwordChange }
                    type="password"
                    data-cy= "login-form-password"/>
            </FormInput>

            <div className="page-signin-form-actions-wrapper">
                <Button 
                    type="text" 
                    size="small" 
                    disabled={ loading }
                    text={ linkForgotText }
                    onClick={ onForgotClick }/>
            
                <Button 
                    type="text"
                    size="small"
                    text="Apply to Join"
                    disabled={ loading }
                    href="https://valery-dev-web.thefloorr.com/?shopper-form"
                    />
            </div>

            <Button 
                data-cy="login-form-submit"
                id="login-submit"
                onClick={ onSubmit }
                loading={ loading }
                disabled={ loading || !email }
                text={ submitText }/>
        </Flex>
        </FormContext.Provider>
    )
}

export default SignInForm
