import { useMemo } from "react"

import Select from "common/src/refactor/components/ant/Select"

const options = [
    {
        key: "all",
        value: "all",
        label: "All Moodboards"
    },
    {
        key: "my",
        value: "my",
        label: "My Moodboards"
    },
    {
        key: "tf",
        value: "tf",
        label: "THE FLOORR Moodboards"
    }
]

export default function TypeSelect({ className, ...props }) {
    const cls = useMemo(
        () => [ "selector-type", className || "" ].join(" "),
        [ className ]
    );

    return (
        <Select
            className={ cls }
            placeholder="All Moodboards"
            popupMatchSelectWidth={ false }
            options={ options }
            { ...props }/>
    )
}